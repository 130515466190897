<template>
	<div class="flex-column full-height bg-base overflow-y-auto">

		<div class="bg-white pa-10 ">

			<div> <Date_picker type="month" :date="item_search.wDate" @click="getSearch"></Date_picker></div>
			<table
				class="table mt-10"
			>
				<thead>
					<tr>
						<th>일자</th>
						<th>총판 등록수</th>
						<th>대리점 등록수</th>
						<th>회원 등록수</th>
						<th>PASS 인증</th>
						<th>카카오 인증</th>

						<th>알림톡 발송</th>
						<th>은행 출금</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>합계</td>
						<td class="font-weight-bold">{{ item_sta.d_count | makeComma }}</td>
						<td class="font-weight-bold">{{ item_sta.a_count | makeComma }}</td>
						<td class="font-weight-bold">{{ item_sta.m_count | makeComma }}</td>
						<td class="font-weight-bold">{{ item_sta.k_count | makeComma }}</td>
						<td class="font-weight-bold">{{ item_sta.n_count | makeComma }}</td>

						<td class="font-weight-bold">{{ item_sta.s_count | makeComma }}</td>
						<td class="font-weight-bold">{{ item_sta.w_count | makeComma }}</td>
					</tr>
					<tr
						v-for="(sta, s_index) in item_list"
						:key="'sta_' + s_index"
					>
						<td>{{ sta.wDate }}</td>
						<td :class="{'font-weight-bold': sta.d_count > 0}">{{ sta.d_count | makeComma }}</td>
						<td :class="{'font-weight-bold': sta.a_count > 0}">{{ sta.a_count | makeComma }}</td>
						<td :class="{'font-weight-bold': sta.m_count > 0}">{{ sta.m_count | makeComma }}</td>
						<td :class="{'font-weight-bold': sta.k_count > 0}">{{ sta.k_count | makeComma }}</td>
						<td :class="{'font-weight-bold': sta.n_count > 0}">{{ sta.n_count | makeComma }}</td>

						<td :class="{'font-weight-bold': sta.s_count > 0}">{{ sta.s_count | makeComma }}</td>
						<td :class="{'font-weight-bold': sta.w_count > 0}">{{ sta.w_count | makeComma }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import Date_picker from "@/components/DatePicker";
export default{
	name: 'Main'
	,props: ['Axios', 'TOKEN', 'date', 'user', 'codes']
	,components: {Date_picker  }
	,data: function(){
		return {
			program: {
				name: '월별 통계'
				,top: true
				,title: true
				,search: false
				,bottom: false
			}
			, items: []
			, item_search: {
				wDate: ''
			}
			, item_sta: {
				d_count: 0
				, a_count: 0
				, m_count: 0
				, k_count: 0
				, n_count: 0
				, s_count: 0
				, w_count: 0
			}
		}
	}
	,computed: {
		item_list: function(){

			return this.items.filter( (item, index) => {
				if(!index){

					this.item_sta.d_count = 0
					this.item_sta.a_count = 0
					this.item_sta.m_count = 0
					this.item_sta.k_count = 0
					this.item_sta.n_count = 0
					this.item_sta.s_count = 0
					this.item_sta.w_count = 0
				}
				this.item_sta.d_count += Number(item.d_count)
				this.item_sta.a_count += Number(item.a_count)
				this.item_sta.m_count += Number(item.m_count)
				this.item_sta.k_count += Number(item.k_count)
				this.item_sta.n_count += Number(item.n_count)
				this.item_sta.s_count += Number(item.s_count)
				this.item_sta.w_count += Number(item.w_count)
				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			const result = await this.$request.init({
				method: 'get'
				,url: 'management/getDashboard'
				,data: {
					wDate: this.item_search.wDate
				}
			})

			if(result.success){
				this.items = result.data.admin_data
			}else{
				this.$bus.$emit('notify', { type: 'error', message: result.message})
			}
		}
		, getSearch: function(d){
			this.item_search.wDate = d
			this.getData()
		}
	}
	,created: function(){
		this.$emit('onLoad', this.program)
		this.getData()
	}
}

</script>

<style>

.label { color: white; border-radius: 5px; padding: 3px 7px 3px; line-height: 12px !important; font-size: 10px;}
.label-new { background-color: #0D47A1;}
.label-hot { background-color: #e64a19;}
.label-recomm { background-color: #0f9d58;}
.label-season { background-color: #00b0ff;}
</style>
